<template>
  <v-row>
    <v-col class="pb-0">
      <v-row
        class="my-0"
      >
        <v-col
          cols="auto"
          class="pr-0 mr-n2 py-0"
        >
          <v-icon
            class="mt-n1"
            title="Common Pathogens"
            :color="item.match === 1 ? 'primary' : undefined"
            size="16"
          >
            {{ item.match === 1 ? 'far' : 'fal' }} fa-fw fa-disease
          </v-icon>
        </v-col>
        <v-col
          v-if="Array.isArray(item.pathogens) && item.pathogens.length"
          class="py-0"
        >
          <v-chip
            v-for="pathogen in item.pathogens"
            :key="pathogen"
            :color="item.match === 1 ? 'primary' : organisms.map(x => x.category ? x.category.name : x.name).includes(pathogen) ? 'orange lighten-3' : null"
            x-small
            class="mr-1 mb-1 px-2"
          >
            {{ pathogen | truncate(30) }}
          </v-chip>
          <v-chip
            v-for="controversial in item.controversial.filter(x => organisms.filter(x => x.controversial).map(x => x.category ? x.category.name : x.name).includes(x))"
            :key="controversial"
            :color="item.match === 1 ? 'primary' : item.controversial.includes(controversial) ? 'orange lighten-3' : null"
            x-small
            class="mr-1 mb-1 px-2"
          >
            {{ controversial | truncate(30) }}
          </v-chip>
          <v-chip
            v-if="item.controversial && item.controversial.length && item.controversial.filter(x => !organisms.filter(x => x.controversial).map(x => x.category ? x.category.name : x.name).includes(x).length)"
            x-small
            outlined
            class="mr-1 mb-1 px-2"
          >
            <v-icon class="ml-n1 mr-1">
              fal fa-plus-circle
            </v-icon>
            Other Controversial Organisms
          </v-chip>
        </v-col>
        <template v-else>
          <v-chip
            v-for="organism in item.all_organisms"
            :key=" organism"
            :color=" item.match === 1 ? 'primary' : organisms.findIndex(x => x.name === organism && x.result.detected) >= 0 ? 'orange lighten-4' : null"
            x-small
            class="mr-1 mb-1 px-2"
            v-text="organism"
          />
        </template>
      </v-row>
      <v-row
        class="my-0"
      >
        <v-col
          cols="auto"
          class="pr-0 mr-n2 py-0"
        >
          <v-icon
            class="mt-n1"
            title="Resistance"
            :color="item.match === 1 ? 'primary' : undefined"
            size="16"
          >
            {{ item.match === 1 ? 'far' : 'fal' }} fa-fw fa-shield-virus
          </v-icon>
        </v-col>
        <v-col
          v-if="item.resistance_tested"
          class="py-0"
        >
          <v-chip
            v-for="resistance in item.resistance"
            :key="resistance"
            :color="resistanceGenes && item.match === 1 ? 'primary' : resistanceGenes.findIndex(x => x.resistance && x.resistance.name === resistance && x.result.detected) >= 0 ? 'orange lighten-3' : null"
            :light="item.match === 1"
            x-small
            label
            :outlined="!relevantResistance.includes(resistance)"
            class="mr-1 mb-1 px-2"
          >
            {{ resistance }}
            <v-icon
              v-if="relevantResistance.includes(resistance)"
              class="ml-1"
              x-small
            >
              fas fa-check
            </v-icon>
          </v-chip>
          <v-chip
            v-if="!item.resistance.length"
            x-small
            label
            class="mr-1 mb-1 px-2"
          >
            None Detected
          </v-chip>
        </v-col>
        <v-col
          v-else
          class="py-0"
        >
          <v-chip
            key="not-tested-chip"
            :color="item.match === 1 ? 'primary' : !resistanceGenes.length ? 'orange lighten-3' : null"
            x-small
            label
            class="mr-1 mb-1 px-2"
          >
            Not Tested
          </v-chip>
        </v-col>
      </v-row>
      <v-row
        v-if="item.diagnosis.length"
        class="my-0"
      >
        <v-col
          cols="auto"
          class="pr-0 mr-n2 py-0"
        >
          <v-icon
            class="mt-n1"
            title="Diagnosis Codes"
            :color="item.match === 1 ? 'primary' : undefined"
            size="16"
          >
            {{ item.match === 1 ? 'far' : 'fal' }} fa-fw fa-diagnoses
          </v-icon>
        </v-col>
        <v-col
          class="py-0"
        >
          <v-chip
            v-for="icd_10_code in item.diagnosis"
            :key="icd_10_code"
            :color="item.match === 1 ? 'primary' : diagnoses && diagnoses.findIndex(x => (x.group ? x.group.name : x.icd_10_code) === icd_10_code) >= 0 ? 'orange lighten-3' : null"
            :light="item.match === 1"
            x-small
            label
            class="mr-1 mb-1 px-2"
            v-text="icd_10_code"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="item.allergies.length"
        class="my-0"
      >
        <v-col
          cols="auto"
          class="pr-0 mr-n2 py-0"
        >
          <v-icon
            class="mt-n1"
            title="Allergies"
            :color="item.match === 1 ? 'primary' : undefined"
            size="16"
          >
            {{ item.match === 1 ? 'far' : 'fal' }} fa-fw fa-allergies
          </v-icon>
        </v-col>
        <v-col
          class="py-0"
        >
          <v-chip
            v-for="allergen in item.allergies"
            :key="allergen"
            :color="allergies && item.match === 1 ? 'primary' : allergies.map(x => x.group ? x.group.name : x.name).includes(allergen) ? 'orange lighten-3' : null"
            :light="item.match === 1"
            x-small
            label
            class="mr-1 mb-1 px-2"
            v-text="allergen"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="item.gender"
        class="my-0"
      >
        <v-col
          :key="item.gender"
          cols="auto"
          class="pr-0 mr-n2 py-0"
        >
          <v-icon
            class="mt-n1"
            :color="item.match === 1 ? 'primary' : undefined"
            size="16"
          >
            {{ item.match === 1 ? 'far' : 'fal' }} fa-fw fa-{{ item.gender }}
          </v-icon>
        </v-col>
        <v-col
          class="py-0"
        >
          <v-chip
            :color="item.match === 1 ? 'primary' : item.gender == gender ? 'orange lighten-3' : null"
            :light="item.match === 1"
            x-small
            label
            class="mr-1 mb-1 px-2"
          >
            {{ item.gender | capitalize }}
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="auto"
      class="text-caption float-right grey--text lighten-1"
    >
      {{ item.created_at | moment('L') }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    organisms: {
      type: Array,
      default: () => ([]),
    },
    resistanceGenes: {
      type: Array,
      default: () => ([]),
    },
    relevantResistance: {
      type: Array,
      default: () => ([]),
    },
    diagnoses: {
      type: Array,
      default: () => ([]),
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    gender:{
      type: [Boolean, String],
      default: false,
    },
  },
}
</script>
